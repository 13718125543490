import React from "react"
import { css, Global } from "@emotion/core"
import styled from "@emotion/styled"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import HeaderDark from "../components/header/header-dark"
import { FloatingText } from "../styled-components/floating-text"

const Wrapper = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ul {
    padding-left: 0;
    list-style: none;
    text-align: center;
    counter-reset: drilldown-items;

    li {
      counter-increment: drilldown-items;
      margin: 0.25rem 0;

      a {
        font-family: "Yantramanav", sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
        color: #1e1e1e;
        text-decoration: none;
        transition: all 0.4s ease-in-out;
        text-transform: uppercase;
        position: relative;

        @media (min-width: 1024px) {
          font-size: 3.25rem;
        }

        @media (min-width: 1024px) {
          font-size: 8vh;
        }

        &:hover {
          color: #2496c3;
        }

        :before {
          display: block;
          content: "0" counter(drilldown-items);
          position: absolute;
          left: -2rem;
          top: 1.25rem;
          color: #fefefe;
          font-family: "Montserrat", sans-serif;
          font-size: 1rem;
          transform: scale(1, 0.9);
          font-weight: 700;
          color: #0a0a0a;
        }
      }
    }
  }
`

const AllServices = ({ data }) => {
  return (
    <>
      <SEO title="All Services" />
      <HeaderDark />
      <Global
        styles={css`
          body {
            background-color: #eff0f2 !important;
          }
        `}
      />
      <FloatingText
        css={css`
          color: #1e1e1e !important;
          pointer-events: none;
        `}
      >
        We Can Help
      </FloatingText>
      <Wrapper>
        <ul>
          {data.allSanityService.edges[0].node.allServices.map(
            (item, index) => (
              <li key={index}>
                <Link to={"/" + item.slug.current + "/"}>
                  {item.title.replace("for Law Firms", "")}
                </Link>
              </li>
            )
          )}
        </ul>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  {
    allSanityService {
      edges {
        node {
          allServices {
            title
            slug {
              current
            }
          }
        }
      }
    }
  }
`

export default AllServices
